<template>
  <div>
    <Header></Header>
    <section class="fixed">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 mx-auto">
            <div class="error-container">
              <span>{{errorCode}}</span>
              <h1>{{title}}</h1>
              <p>{{message}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/components/Footer";
import Header from "@/views/components/Header";
import MixIn from "@/common/mixin";
import Vue from 'vue'
export default {
  name: "Error",
  components: {Footer, Header},
  mixin: {MixIn},
  data: function () {
    return {
      errorCode: Vue.prototype.$error ? Vue.prototype.$error.errorCode : "500",
      title: Vue.prototype.$error ? Vue.prototype.$error.title : "エラーが発生しました。",
      message: Vue.prototype.$error ? Vue.prototype.$error.message : "予期せぬエラーが発生しました。\nページの再読み込み等を試しても\n改善しない場合には、\n運営事務局までご連絡ください。"
    }
  },
  created() {
    Vue.prototype.$error = undefined;
  }
}
</script>

<style scoped>
  div {
    white-space: pre-line;
  }
</style>
